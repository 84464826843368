.content-block,
.pimcore_area_content{
    float: none;
    clear: both;
}
.content-block + .content-block,
.pimcore_area_content + .content-block,
.pimcore_area_content > .highlight-slide,
.content-block + .pimcore_area_content > .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block,
.pimcore_area_content + .pimcore_area_content > .pimcore_area_content > .content-block {
    margin-top: 30px;
}

@media screen and (min-width: 768px) {
    .content-block + .content-block,
    .pimcore_area_content + .content-block,
    .pimcore_area_content > .highlight-slide,
    .content-block + .pimcore_area_content > .content-block,
    .pimcore_area_content + .pimcore_area_content > .content-block,
    .pimcore_area_content + .pimcore_area_content > .pimcore_area_content > .content-block {
        margin-top: 80px;
    }
}


.content-block--sm + .content-block--sm,
.pimcore_area_content + .content-block--sm,
.content-block--sm + .pimcore_area_content > .content-block--sm,
.pimcore_area_content + .pimcore_area_content > .content-block--sm,
.pimcore_area_content + .pimcore_area_content > .pimcore_area_content > .content-block--sm {
    margin-top: 20px;
}
@media screen and (min-width: 768px) {
    .content-block--sm + .content-block--sm,
    .pimcore_area_content + .content-block--sm,
    .content-block--sm + .pimcore_area_content > .content-block--sm,
    .pimcore_area_content + .pimcore_area_content > .content-block--sm,
    .pimcore_area_content + .pimcore_area_content > .pimcore_area_content > .content-block--sm {
        margin-top: 40px;
    }
}

.overflow-hidden{
    overflow: hidden;
}