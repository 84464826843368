.aktie {
    color:#fff;
    padding: 20px 0;
    background: url(/static/img/backgrounds/bgAktie.jpg) 50% 50% no-repeat;
    background-size: cover;
    @media screen and (min-width: 768px) {
        padding: 50px 0;
    }
}
.aktie__item {
    padding: 3px 6px;
}
.aktie__item--highlight {
    background-color: rgba(27,27,27,0.3);
}