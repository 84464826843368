/* ag footer styles */
.main-footer__ag-infos {
    color:#fff;
    padding: 20px 0 0 0;
    background: url(/static/img/backgrounds/bg-contact.png) 50% 50% no-repeat;
    background-size: cover;

    @media screen and (min-width: 768px) {
        padding: 60px 0 40px 0;
    }
}


@media screen and (max-width: 767px) {
    .main-footer__ag-infos-col  {
        margin-bottom:25px;
    }
    .main-footer__ag-infos-hl {
        margin-bottom:0;
    }
}