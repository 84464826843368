@media screen and (max-width: 767px){
    .navbar-toggle .icon-bar {
        background:#3d3d3d;
    }

    .navbar-main.is-open {
        background:#fff;
    }
    .mobile-nav {
        color: #000;
        background:#fff;
    }
    .navbar-nav>li>a:focus, .navbar-nav>li>a:hover {
        color: var(--color-primary);
    }
}