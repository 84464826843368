.navbar__search__form__input {
    background:#fff;
    color:#000;
}
.navbar__search__form__input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #000;
}
.navbar__search__form__input::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #000;
}
.navbar__search__form__input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #000;
}