@media screen and (min-width: 768px){
    .navbar-main__dropdown {
        background: rgba(255,255,255,0.95);
        box-shadow: 0 0 5px rgba(0,0,0,.25);
    }
}

.navbar-main__dropdown li>a:hover {
    color: var(--color-primary);
}

@media screen and (min-width: 768px) {
    .navbar-main__dropdown__info {
        background: var(--color-dark);
    }
}