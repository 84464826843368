.page-nav {
    border-top: 1px solid var(--color-grey-light);
}

.page-nav__icon {
    font-size: 30px;
}

.page-nav__arrow-wrapper {
    line-height: 1;
}

.page-nav__item {
    display: inline-flex;
    align-items: center;
}