.slide {
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
}

.pimcore_area_ag-fullslide{
    overflow: hidden;
}

@media screen and (min-width: 768px) {
    .slide {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

.slide--padding-md {
    padding-top: 20px;
    padding-bottom: 20px;
}

@media screen and (min-width: 768px) {
    .slide--padding-md {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.slide--padding-lg {
    padding-top: 25px;
    padding-bottom: 25px;
}
.slide--bottom-padding-lg {
    padding-bottom: 25px;
}
.page-heading + .pimcore_area_content > .slide {
    padding-top: 10px;
}
@media screen and (min-width: 768px) {
    .slide--padding-lg {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .slide--bottom-padding-lg {
        padding-bottom: 100px;
    }
    .page-heading + .pimcore_area_content > .slide {
        padding-top: 30px;
    }
}

.slide.slide--padding-sm {
    padding-top: 10px;
    padding-bottom: 10px;
}

.slide--with-background {
    background-size: cover;
    background-repeat: no-repeat;
}

.slide--no-bottom-padding.slide--no-bottom-padding {
    padding-bottom: 0;
}
.slide--no-top-padding.slide--no-top-padding {
    padding-top: 0;
}

.slide--bordered {
    border-top: 1px solid #C4C6C7;
    border-bottom: 1px solid #C4C6C7;
}
.slide--bordered + .slide--bordered {
    border-top: 0;
}

.main-headline {
    margin-bottom:15px;
    @media screen and (min-width: 768px) {
        margin-bottom:40px;
    }
}