.navbar-main {
    color: #3d3d3d;
    background-image: linear-gradient(0deg,transparent 0,rgba(255,255,255,.8));
}
.navbar-main .active>a {
    color: #000;
    border-bottom: 2px solid #000;
}


@media screen and (min-width: 768px) {
    .navbar-nav>li>a:hover,
    .navbar-nav>li>a:focus {
        color: #000;
    }
    .affix-nav-bar.is-affix {
        background: rgba(255,255,255,1);
        box-shadow: 0 3px 3px rgba(0,0,0,.1);
        border-bottom: 1px solid #f0f0f0;
    }
}
.lang-item {
    font-family: var(--font-bold-family);
    display: inline-block;
}
.lang-active {
    /*color:var(--color-primary);*/
    text-decoration: underline;
}
@media screen and (min-width: 768px) {

    .navbar__top.hide_top_navigation {
        height: 20px;
    }

    .navbar__top .navbar-nav>li {
        padding: 0 15px;
    }
    .navbar__top .navbar-nav>li.lang-divider {
        padding: 0;
    }
}

.navbar-nav.navbar-nav>li {
    @media screen and (min-width: 768px){
        padding: 0 12px 15px;
    }
    @media screen and (min-width: 1440px){
        padding: 0 20px 15px;
    }
}

.navbar-nav.navbar-nav>li>a {
    @media screen and (min-width: 768px) {
        font-size: 14px;
    }
    @media screen and (min-width: 1200px) {
        font-size: 16px;
    }
    @media screen and (min-width: 1440px) {
        font-size: 20px;
    }
}