.page-heading {
    position: relative;
    padding-top: 10px;
}
@media screen and (min-width: 768px){
    .page-heading {
        padding-top: 40px;
        /*margin-bottom:-30px;*/
    }
}

@media screen and (max-width: 767px) {
    .page-heading.page-heading--no-padding-xs {
        padding: 0;
    }
}