.wysiwyg table,
.pimcore_area_content table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    font-size: 14px;
    border:0;
    border-color: transparent;
}

/*.pimcore_area_content table>tbody>tr:nth-of-type(odd) {
    background-color: #f1f1f1;
}*/
.wysiwyg table>tbody>tr>td,
.pimcore_area_content table>tbody>tr>td {
    border-top: 1px solid #f1f1f1;
    background: transparent !important;
}

.wysiwyg table>tbody>tr:last-child>td,
.pimcore_area_content table>tbody>tr:last-child>td {
    border-bottom: 1px solid #f1f1f1;
}

.wysiwyg table>tbody>tr>td,
.wysiwyg table>tbody>tr>th,
.wysiwyg table>tfoot>tr>td,
.wysiwyg table>tfoot>tr>th,
.wysiwyg table>thead>tr>td,
.wysiwyg table>thead>tr>th,
.pimcore_area_content table>tbody>tr>td,
.pimcore_area_content table>tbody>tr>th,
.pimcore_area_content table>tfoot>tr>td,
.pimcore_area_content table>tfoot>tr>th,
.pimcore_area_content table>thead>tr>td,
.pimcore_area_content table>thead>tr>th {
    padding: 9px 22px 11px;
    line-height: 1.42857143;
    vertical-align: top;
}

.wysiwyg table>tbody>tr>td:first-child,
.wysiwyg table>tbody>tr>th:first-child,
.wysiwyg table>tfoot>tr>td:first-child,
.wysiwyg table>tfoot>tr>th:first-child,
.wysiwyg table>thead>tr>td:first-child,
.wysiwyg table>thead>tr>th:first-child,
.pimcore_area_content table>tbody>tr>td:first-child,
.pimcore_area_content table>tbody>tr>th:first-child,
.pimcore_area_content table>tfoot>tr>td:first-child,
.pimcore_area_content table>tfoot>tr>th:first-child,
.pimcore_area_content table>thead>tr>td:first-child,
.pimcore_area_content table>thead>tr>th:first-child {
    padding-left: 30px;
}